import "core-js/modules/es6.array.map.js";
export default defineComponent({
  props: {
    impact: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var items = computed(function () {
      return props.impact.sdgs.map(function (e) {
        return "SDG ".concat(e.id, " - ").concat(e.label);
      });
    });
    return {
      items: items
    };
  },
  computed: {}
});